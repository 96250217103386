import { useRequestEvent } from '#imports'

import { invariant } from '@backmarket/utils/function/invariant'

import type { HttpFetch } from '../utils/createHttpFetch'
import { getRequestHttpFetch } from '../utils/getRequestHttpFetch'

/**
 * Retrieve the current `$httpFetch` instance automatically, based on the
 * surrounding context. On the server, the instance will be retrieved from
 * the H3 event. On the client, the instance will be retrieved from the
 * global `window` object.
 */
export function useHttpFetch(): HttpFetch {
  const event = useRequestEvent()

  if (event) {
    return getRequestHttpFetch(event)
  }

  invariant(window.$httpFetch, 'window.$httpFetch is missing')

  return window.$httpFetch
}
