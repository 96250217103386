<template>
  <div>
    <BlockTitle :subtitle="subtitle" :title="title" :title-id="id" />

    <div v-if="hasSingleCategory">
      <CategoryCard :category="onlyCategory" :tracking="tracking" />
    </div>

    <RevCardGrid v-else :aria-labelledby="id" :cards="categories">
      <template #default="{ card }: { card: Category }">
        <CategoryCard :category="card" :tracking="trackingData(card)" />
      </template>
    </RevCardGrid>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type {
  CategoriesProps,
  Category,
} from '@backmarket/http-api/src/api-specs-content/models/categories'
import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { RevCardGrid } from '@ds/components/CardGrid'

import CategoryCard from './CategoryCard.vue'

const props = defineProps<CategoriesProps & ContentBlockProps>()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const hasSingleCategory = computed(() => props.categories?.length === 1)

const onlyCategory = computed(() => props.categories?.[0])

// TODO: drop this once RevCardGrid injects the card index
const trackingData = (card: Category) => ({
  ...(props.tracking || {}),
  position:
    props.categories.findIndex((category) => category.id === card.id) + 1,
})
</script>
