import type { RouteParams } from 'vue-router'

import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

type QueryParams = RouteParams[keyof RouteParams]
export const buildTrackingZone = (params: QueryParams[]) => {
  return params
    .filter((value) => !isEmpty(value))
    .map((value) => (Array.isArray(value) ? value[0] : value))
    .join('_')
}

export const trackingData = ({
  pageName,
  pageType,
  pageCategory = '',
  blockName = 'UNKNOWN',
  blockPosition,
  technicalName = '',
}: {
  pageName: string
  pageType: QueryParams
  pageCategory?: QueryParams
  blockName?: string
  blockPosition: number
  technicalName?: string
}): Tracking => ({
  zone: buildTrackingZone([pageType, pageName, pageCategory]),
  value: blockName,
  blockPosition,
  technicalName,
})
