import humps, { type OptionOrProcessor } from 'humps'

/**
 * Transform string from camelCase to snake_case.
 *
 * This helper is here to provide better type safety around the underlying
 * methods from the `humps` package. We want to throw type errors when
 * this is called with class instances (like FormData, URLSearchParams).
 *
 * @example
 * decamelize('helloWorld') // 'hello_world'
 * decamelize('helloWorld', { separator: '-' }) // 'hello-world'
 */
export function decamelize(
  value: string,
  optionsOrProcessor?: OptionOrProcessor,
) {
  return humps.decamelize(value, optionsOrProcessor)
}
