<template>
  <RevContainer>
    <div class="flex flex-col gap-32 pt-40 md:flex-row md:pt-0">
      <div class="flex flex-col items-start justify-center space-y-20 md:w-1/2">
        <h2 class="heading-1">{{ i18n(translations.title) }}</h2>

        <FormattedMessage :definition="translations.subtitle">
          <template #logo>
            <RevIllustration
              alt="BackUp logo"
              class="inline align-middle"
              :height="21"
              src="/img/insurances/backUpLogo.svg"
              :width="96"
            />
          </template>
        </FormattedMessage>

        <div>
          <RevButton
            :to="resolveLocalizedRoute({ name: HOME })"
            variant="primary"
          >
            {{ i18n(translations.back) }}
          </RevButton>
        </div>

        <RevLink
          :to="
            resolveLocalizedRoute({
              name: CMS.EVENT,
              params: { pageName: 'backup' },
            })
          "
        >
          {{ i18n(translations.questions) }}
        </RevLink>
      </div>

      <div class="flex items-center justify-center py-48 md:w-1/2 md:py-72">
        <RevIllustration
          alt=""
          :height="403"
          src="/img/checkout/emptyBasket.svg"
          :width="381"
        />
      </div>
    </div>
  </RevContainer>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import { CMS } from '~/scopes/cms/routes-names'
import { HOME } from '~/scopes/home/route-names'

import translations from './Expired.translations'

const resolveLocalizedRoute = useRouteLocationWithLocale()
const i18n = useI18n()
</script>
