import { defineStore } from 'pinia'

interface State {
  isEnabled: boolean
  message: string
}

export const useLoaderStore = defineStore('loaderStore', {
  state: (): State => ({
    isEnabled: false,
    message: '',
  }),
  actions: {
    enable(payload: { message: string } = { message: '' }) {
      this.isEnabled = true
      this.message = payload.message
    },
    disable() {
      this.isEnabled = false
    },
  },
})
