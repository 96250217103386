<template>
  <RevCategoryCard
    v-if="hasValidLink(category)"
    class="h-320"
    data-test="link-category-card"
    :description="category.tagline || ''"
    :image="category.image"
    :link="category.link?.href"
    :rel="category.link?.rel"
    :target="category.link?.target"
    :title="category.title"
    @click="sendTrackingData"
  />

  <StaticCategoryCard
    v-else
    data-test="static-category-card"
    v-bind="category"
  ></StaticCategoryCard>
</template>

<script setup lang="ts">
import type {
  Category,
  CategoryWithLink,
} from '@backmarket/http-api/src/api-specs-content/models/categories'
import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevCategoryCard } from '@ds/components/CategoryCard'

import StaticCategoryCard from './StaticCategoryCard.vue'

const props = withDefaults(
  defineProps<{ category: Category; tracking?: Tracking }>(),
  { tracking: () => ({}) },
)

function hasValidLink(category: Category): category is CategoryWithLink {
  return !isEmpty(category.link)
}

const { trackClick } = useTracking()

function sendTrackingData() {
  trackClick({ ...props.tracking, name: props.category.title })
}
</script>
