export default {
  title: {
    id: 'post_purchase_expired_title',
    defaultMessage: 'Sorry, maybe next time',
  },
  subtitle: {
    id: 'post_purchase_expired_subline',
    defaultMessage:
      '{logo, html} protection can only be added 30 days after your purchase.',
  },
  back: {
    id: 'post_purchase_expired_back',
    defaultMessage: 'Go back',
  },
  questions: {
    id: 'post_purchase_expired_title_questions',
    defaultMessage: 'Got BackUp questions?',
  },
}
