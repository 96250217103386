export default {
  hoursInHours: {
    id: 'date_helper_hours_in_hours',
    defaultMessage: '{hours}h',
  },
  hoursInDays: {
    id: 'date_helper_hours_in_days',
    defaultMessage: '{days} days',
  },
}
