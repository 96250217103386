import { useHead, useRequestURL } from '#imports'
import { useRoute } from 'vue-router'

/**
 * Adds a canonical link to the head based on the current route
 */
export function useAddCanonicalLink() {
  const route = useRoute()
  const { hostname, protocol } = useRequestURL()

  useHead({
    link: [
      {
        rel: 'canonical',
        href: `${protocol}//${hostname}${route.path}`,
      },
    ],
  })
}
