export const isAgeLegal = ({
  legalAge,
  birthdate,
}: {
  legalAge: number
  birthdate: Date
}) => {
  const legalDate = new Date(birthdate)
  legalDate.setFullYear(Number(birthdate.getFullYear()) + legalAge)

  return new Date(legalDate).getTime() - new Date().getTime() < 0
}
