export default {
  title: {
    id: 'password_reset_confirm_title',
    defaultMessage: 'Yipeeh!',
  },
  retry: {
    id: 'password_reset_confirm_retry',
    defaultMessage:
      'If you did not receive the email or if the email address is incorrect, try again {link, html}',
  },
  retryLink: {
    id: 'password_reset_confirm_text_retry_link',
    defaultMessage: 'here',
  },
  emailSent: {
    id: 'password_reset_confirm_email_sent',
    defaultMessage: 'A link to reset your password has been sent to {email}.',
  },
}
