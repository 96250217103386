export default {
  days: {
    id: 'event_countdown_days',
    defaultMessage: `{duration, plural,
      one {day}
      other {days}
    }`,
  },
  hours: {
    id: 'event_countdown_hours',
    defaultMessage: `{duration, plural,
      one {hour}
      other {hours}
    }`,
  },
  minutes: {
    id: 'event_countdown_minutes',
    defaultMessage: `{duration, plural,
      one {minute}
      other {minutes}
    }`,
  },
}
