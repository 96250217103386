/**
 * Function that transforms a date like DD/MM/YYYY or MM-DD-YYYY to YYYY-MM-DD
 * @param {string} date Date with format DD/MM/YYYY or MM-DD-YYYY
 */
export const formatDateToYYYYMMDD = (date: string) => {
  const isUsDateFormat = date.includes('-') && date.split('-')[0].length === 2
  const euToIso = (dateEu: string) => dateEu.split('/').reverse().join('-')
  function usToIso(dateUs: string) {
    const [month, day, year] = dateUs.split('-')

    return `${year}-${month}-${day}`
  }

  return isUsDateFormat ? usToIso(date) : euToIso(date)
}
