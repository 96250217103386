import type { TimeDifference } from './models'

export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR

export function getTimeRemaining({
  from,
  to,
}: {
  from: Date
  to: Date
}): TimeDifference {
  const msDiff = Math.max(to.getTime() - from.getTime(), 0)

  const daysDiff = Math.floor(msDiff / DAY)

  const hoursMsDiff = msDiff % DAY
  const hoursDiff = Math.floor(hoursMsDiff / HOUR)

  const minutesMsDiff = msDiff % HOUR
  const minutesDiff = Math.floor(minutesMsDiff / MINUTE)

  return {
    days: daysDiff,
    hours: hoursDiff,
    minutes: minutesDiff,
  }
}

export function endIsBeforeStart({ start, end }: { start: Date; end: Date }) {
  return end.getTime() - start.getTime() < 0
}
