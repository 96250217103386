import { type MaybeRef, computed, toValue } from 'vue'

import {
  type CountdownDigits,
  type TimeDifference,
  type TimeUnit,
} from './models'

const formatDigit = (raw: number) =>
  raw.toString().length >= 2 ? raw.toString() : `0${raw.toString()}`

export function useCountdownDigits(
  timeDifferenceRef: MaybeRef<TimeDifference>,
  unit: TimeUnit,
) {
  return computed<CountdownDigits>(() => {
    const timeDifference = toValue(timeDifferenceRef)
    const rawDigits = timeDifference[unit]

    return {
      raw: rawDigits,
      formatted: formatDigit(rawDigits),
    }
  })
}
