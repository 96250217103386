/**
 * Custom error class tailored for invariant errors.
 */
export class InvariantError extends Error {
  name = 'InvariantError'
}

/**
 * Asserts that the condition is _truthy_. If not, throw an error.
 *
 * This is particularly useful to enforce that something is defined before
 * executing the rest of the code. It's not always possible via TypeScript
 * since you might not control dependency types.
 *
 * @throws {InvariantError} if the condition is falsy.
 *
 * @example
 * // Before
 * if (event) {
 *   // Do something with the event.
 * }
 *
 * // After
 * invariant(event, 'Event must be defined')
 * // Do something with the event.
 *
 * @see https://www.npmjs.com/package/invariant
 * @see https://github.com/alexreardon/tiny-invariant
 */
export function invariant(
  condition: unknown,
  message: string,
): asserts condition {
  if (!condition) {
    throw new InvariantError(message)
  }
}
