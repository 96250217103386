import { useRoute } from '#imports'
import type { RouteParamValue } from 'vue-router'

/**
 * Composable to retrieve the current route params with the right typings and failsafe.
 * vue-router returns params as Record<string, RouteParamValue | RouteParamValue[]>
 * we'll mostly use them as Record<string, RouteParamValue>
 *
 * @example
 * To retrieve the :productId param
 * ```
 * const { productId } = useRouteParams()
 * ```
 */
export function useRouteParams(): Record<string, RouteParamValue> {
  const { params } = useRoute()

  return Object.keys(params).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: Array.isArray(params[cur]) ? params[cur][0] : params[cur],
    }),
    {},
  )
}
