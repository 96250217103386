import type { ExternalLink } from '../../api-models/LinkExternal'

export type BundledMobilePlanActionType = 'redirect_to_partner_portal'

export const ORDERLINE_BUNDLED_MOBILE_PLAN_ACTIONS: Record<
  string,
  BundledMobilePlanActionType
> = {
  redirectToPartnerPortal: 'redirect_to_partner_portal',
}

export type OrderlineBundledMobilePlanStateLabelClass =
  | 'DEFAULT'
  | 'SUCCESS'
  | 'DANGER'
  | 'WARNING'

export type OrderlineBundledMobilePlanState = {
  enumState:
    | 'AWAITING_PAYMENT'
    | 'SUBSCRIBED'
    | 'NOT_ELIGIBLE'
    | 'AWAITING_CANCELATION'
    | 'CANCELED'
    | 'UNKNOWN'
  date: string | null
  label: string | null
  labelClass: OrderlineBundledMobilePlanStateLabelClass | null
}

export type OrderlineBundledMobilePlanPartnerProvider = 'BOUYGUES_TELECOM'

export const ORDERLINE_BUNDLED_MOBILE_PLAN_PARTNERS_PROVIDERS: Record<
  string,
  OrderlineBundledMobilePlanPartnerProvider
> = {
  bouygues: 'BOUYGUES_TELECOM',
}

export type OrderlineBundledMobilePlan = {
  state: OrderlineBundledMobilePlanState
  mobilePlanOfferLabel: string
  partnerPortalUrl: string | null
  partnerProvider: OrderlineBundledMobilePlanPartnerProvider
  partnerProviderLabel: string
  contractCommitmentLabel: string
  priceLabel: string | null
  priceSubLabel: string | null
  actions: {
    type: BundledMobilePlanActionType
    active: boolean
    label: string
    hint: string | null
    link: ExternalLink | null
    downloadable: boolean
  }[]
}

export const ORDERLINE_TIMELINE_STEP_TYPE = {
  delivery: 'delivery',
  payment: 'payment',
  shipment: 'shipment',
  validation: 'validation',
} as const

export type StepType =
  (typeof ORDERLINE_TIMELINE_STEP_TYPE)[keyof typeof ORDERLINE_TIMELINE_STEP_TYPE]

export type OrderlineTimeline = {
  type: StepType | null
  date: string | null
  current: boolean
  label: string
  hint: string | null
  details: string | null
}

export type OrderlineLink = {
  id: number
  /**
   * Publicid
   * @format uuid
   */
  publicId: string
  orderId: number
}

export type Tag = {
  label: string
  labelClass: 'DEFAULT' | 'INFO' | 'SUCCESS' | 'DANGER' | 'WARNING' | null
}
