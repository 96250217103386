export const LEGAL_PAGE_NAME = {
  DATA_PROTECTION: 'data-protection',
  OTHER: 'other-information',
  BUYBACK: 'terms-of-trade-in',
  COOKIES: 'cookies',
  TERMS_SALE: 'terms-of-sale',
  TERMS_USE: 'terms-of-use',
  TERMS_SERVICE: 'terms-of-service',
  WARRANTY: 'warranty',
  LEGAL_MENTIONS: 'legal-mentions',
}
