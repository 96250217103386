import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './useFormatHours.translations'

export function useFormatHours() {
  const i18n = useI18n()

  function getFormatHoursInDay(hours: number) {
    if (hours >= 72) {
      return i18n(translations.hoursInDays, { days: Math.ceil(hours / 24) })
    }

    return i18n(translations.hoursInHours, { hours })
  }

  return { getFormatHoursInDay }
}
